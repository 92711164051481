<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Write the appropriate acid-base equilibrium reaction for the addition of
        <chemical-latex :content="molecule" />
        to liquid water. Please use '&lt;=&gt;' for the equilibrium arrow in your reaction.
      </p>

      <chemical-notation-input
        v-model="inputs.rxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) Explain how you came to your answer in part a).</p>

      <p class="mb-n5">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import integerValue from '@/tasks/components/IntegerValue.vue';
import STextarea from '@/common/components/STextarea.vue';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default {
  name: 'Question468AI',
  components: {
    STextarea,
    ChemicalNotationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rxn: null,
        explainedResponse: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    molecule() {
      if (this.versionNumber.value === 1) {
        return 'CH3NH2(aq)';
      } else if (this.versionNumber.value === 2) {
        return 'HF(aq)';
      } else if (this.versionNumber.value === 3) {
        return 'CH3NH3+(aq)';
      } else {
        return 'F-(aq)';
      }
    },
  },
};
</script>
