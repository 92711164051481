<template>
  <latex-number :number="value.value.toString()" :style="style" />
</template>

<script>
import IV from '../../task-values/models/legacy/LegacyIntegerValue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';
import {useRandomNumberBackgroundStyle} from '@/tasks/components/composables/useRandomNumberBackgroundStyle';

export default {
  name: 'IntegerValue',
  components: {LatexNumber},
  props: {
    value: {
      type: IV,
      required: true,
    },
    unit: {
      type: String,
      default: null,
    },
  },
  setup() {
    return useRandomNumberBackgroundStyle();
  },
};
</script>
